import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Alert,
  TextField,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Navbar from '../../views/Navbar';
import { getAllJobDescriptions } from '../../utility/api';
import EmployeeHoursModal from './EmployeeHoursModal'; 

function JobDescriptions() {
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        let data = await getAllJobDescriptions();
        data.sort((a, b) => b.id - a.id);
        setJobDescriptions(data);
      } catch (error) {
        console.error('Error fetching job descriptions:', error);
        setAlertMessage('Failed to fetch job descriptions.');
        setAlertSeverity('error');
        setShowAlert(true);
      }
    };
    fetchJobs();
  }, []);

  const filteredJobDescriptions = searchQuery
    ? jobDescriptions.filter((job) =>
        job.location.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : jobDescriptions;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredJobDescriptions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredJobDescriptions.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handleOpenModal = (jobId) => {
    setCurrentJobId(jobId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Navbar />
      {showAlert && (
        <Alert severity={alertSeverity} sx={{ margin: 2 }}>
          {alertMessage}
        </Alert>
      )}
      <div style={{ margin: '20px' }}>
        <TextField
          variant="outlined"
          label="Search by Job"
          fullWidth
          margin="normal"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Link to="/job-descriptions/new">
          <Button variant="contained" sx={{ m: 1 }}>
            Add New Job
          </Button>
        </Link>
        {currentItems.map((job) => (
          <Card key={job.id} variant="outlined" sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {job.location}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Contracted By: {job.contractedBy}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                component={Link}
                to={`/job-details/job-description-details/${job.id}`}
                size="small"
              >
                View Details
              </Button>
              <Button
                onClick={() => handleOpenModal(job.id)}
                size="small"
              >
                View Hours
              </Button>
            </CardActions>
          </Card>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          {pageNumbers.map((number) => (
            <Button
              key={number}
              onClick={() => paginate(number)}
              sx={{ mx: 1 }}
            >
              {number}
            </Button>
          ))}
        </Box>
      </div>
      <EmployeeHoursModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        jobId={currentJobId}
      />
    </>
  );
}

export default JobDescriptions;

