import { getToken } from './utils'
//The base url of the API, can be changed in the .env file
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:9000'
/**
 * Sends a login request to the api for a user with the provided username and password.
 *
 * @async
 * @function
 * @param {Object} data - An object containing the user's login credentials.
 * @param {string} data.name - The user's name.
 * @param {string} data.pin - The user's pin number.
 * @returns {Promise<Object>} - A promise that resolves with the user's data.
 * @throws {Error} - Throws an error if there was an issue with the login request.
 */
export const login = async (data) => {
	const { name, pin } = data

	const response = await fetch(`${baseUrl}/employees/login`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ name, pin }),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

/**
 * Sends a registration request to the api for a user with the provided data.
 *
 * @async
 * @function
 * @param {Object} data - An object containing the user's data require to create an account.
 * @param {string} data.name - The user's name
 * @param {string} data.pin - The user's pin number
 * @param {*} data.[...] - Any additional user data required for account creation
 * @returns {Promise<Object>} - A promise that resolves with the user's data.
 * @throws {Error} - Throws an error if there was an issue with the login request.
 */

export const register = async (data) => {
	const response = await fetch(`${baseUrl}/employees/register`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const getMe = async () => {
	const token = getToken()
	if (!token) {
		throw new Error(`Missing User Token`)
	}

	const response = await fetch(`${baseUrl}/employees/me`, {
		method: 'GET',
		headers: new Headers({
			Authorization: `Bearer ${token}`, //Token is required for protected Routes
		}),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}
	return responseData
}

export const getAllEmployees = async () => {
	const token = getToken()
	const response = await fetch(`${baseUrl}/employees/`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})

	if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`)
	}

	return response.json()
}

export const createNewEmployee = async (employeeData) => {
	const response = await fetch(`${baseUrl}/employees/new`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(employeeData),
	})

	const responseData = await response.json()
	if (!response.ok) {
		return {
			success: false,
			error: `Status Code: ${response?.status} - ${responseData?.message}`,
		}
	}
	return { success: true }
}

export const getEmployeeById = async (id) => {
	const token = getToken()
	const response = await fetch(`${baseUrl}/employees/${id}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})

	const responseData = await response.json()
	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const deleteEmployee = async (id) => {
	const token = getToken()
	if (!token) {
		throw new Error(`Missing User Token`)
	}

	const response = await fetch(`${baseUrl}/employees/delete/${id}`, {
		method: 'DELETE',
		headers: new Headers({
			Authorization: `Bearer ${token}`, //Token is required for protected Routes
		}),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const updateEmployee = async (data) => {
	console.log(data)
	const token = getToken()
	const response = await fetch(`${baseUrl}/employees/update/${data.id}`, {
		method: 'PUT',
		headers: new Headers({
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		}),
		body: JSON.stringify(data),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const createNewTimeEntry = async (timeEntryData) => {
	try {
		const response = await fetch(`${baseUrl}/timeEntries/new`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(timeEntryData),
		})

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}

		return await response.json()
	} catch (error) {
		console.error('Error in createNewTimeEntry: ', error)
		throw error // Re-throw to allow the calling component to handle it
	}
}

export const getTimeEntryById = async (id) => {
	const response = await fetch(`${baseUrl}/timeEntries/${id}`, {
		method: 'GET',
	})

	const responseData = await response.json()
	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const getTimeEntriesByEmployeeId = async (employeeId) => {
	const response = await fetch(`${baseUrl}/timeEntries/id/${employeeId}`, {
		method: 'GET',
	})

	const responseData = await response.json()
	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const updateTimeEntry = async (data) => {
	console.log(data)
	const response = await fetch(`${baseUrl}/timeEntries/update/${data.id}`, {
		method: 'PUT',
		headers: new Headers({
			'Content-Type': 'application/json',
		}),
		body: JSON.stringify(data),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const getAllTimeEntries = async () => {
	const response = await fetch(`${baseUrl}/timeEntries/`)
	const responseData = await response.json()
	return responseData
}

export const addJobDescription = async (jobEntryData) => {
	try {
		const response = await fetch(`${baseUrl}/jobDescriptions/new`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(jobEntryData),
		})

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}

		return await response.json()
	} catch (error) {
		console.error('Error in createNewTimeEntry: ', error)
		throw error // Re-throw to allow the calling component to handle it
	}
}

export const getJobDescriptionById = async (employeeId) => {
	const response = await fetch(`${baseUrl}/jobDescriptions/id/${employeeId}`, {
		method: 'GET',
	})

	const responseData = await response.json()
	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const updateJobDescription = async (data) => {
	console.log(data)
	const token = getToken() // Retrieve the authentication token
	const response = await fetch(`${baseUrl}/jobDescriptions/update/${data.id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include the token in the Authorization header
		},
		body: JSON.stringify(data),
	})
	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const getAllJobDescriptions = async () => {
	const response = await fetch(`${baseUrl}/jobDescriptions/`)
	const responseData = await response.json()
	return responseData
}

export const deleteJobDescription = async (id) => {
	const token = getToken()
	if (!token) {
		throw new Error(`Missing User Token`)
	}

	const response = await fetch(`${baseUrl}/jobDescriptions/delete/${id}`, {
		method: 'DELETE',
		headers: new Headers({
			Authorization: `Bearer ${token}`, //Token is required for protected Routes
		}),
	})

	const responseData = await response.json()

	if (!response.ok) {
		throw new Error(
			`Status Code: ${response?.status} - ${responseData?.message}`
		)
	}

	return responseData
}

export const getEmployeeHoursByJobId = async (jobId) => {
	try {
		const response = await fetch(`${baseUrl}/employeeHours/${jobId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const data = await response.json()
		return data
	} catch (error) {
		console.error('Error fetching employee hours:', error)
		throw error
	}
}

export const getJobDetailsByJobDescriptionId = async (jobDescriptionId) => {
	try {
		const response = await fetch(`${baseUrl}/jobDetails/by-job-description/${jobDescriptionId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		const data = await response.json()
		return data
	} catch (error) {
		console.error('Error fetching job details:', error)
		throw error
	}
}

export const addJobDetails = async (jobDetailsData) => {
	try {
		const response = await fetch(`${baseUrl}/jobDetails/new`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(jobDetailsData),
		})

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}

		return await response.json()
	} catch (error) {
		console.error('Error in createNewJobDetails: ', error)
		throw error // Re-throw to allow the calling component to handle it
	}
}

export const getTotalHoursForJobDescription = async (jobDescriptionId) => {
	try {
			const response = await fetch(`${baseUrl}/jobDescriptions/${jobDescriptionId}/total-hours`, {
					method: 'GET',
					headers: {
							'Content-Type': 'application/json',
					},
			});

			if (!response.ok) {
					throw new Error(`Network response was not ok: ${response.statusText}`);
			}

			const data = await response.json();
			return data.totalHours; // Assuming the backend sends the total hours in a property named totalHours
	} catch (error) {
			console.error('Error fetching total hours for job description:', error);
			throw error; // Re-throw to allow calling component to handle it
	}
};

export const getTotalHoursForJobDetailsId = async (jobDetailId) => {
	try {
		const response = await fetch(`${baseUrl}/jobDetails/${jobDetailId}/total-hours`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`);
		}

		const data = await response.json();
		return data.totalHours; // Assuming the backend sends the total hours in a property named totalHours
	} catch (error) {
		console.error('Error fetching total hours for job details:', error);
		throw error; // Re-throw to allow calling component to handle it
	}
};