import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getEmployeeById, updateEmployee } from '../../utility/api'
import {
	TextField,
	Button,
	Container,
	Typography,
	Box,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Alert,
} from '@mui/material'

function UpdateEmployee() {
	const [employeeData, setEmployeeData] = useState({
		name: '',
		pin: '',
		address: '',
		type: '',
		payRate: '',
		role: '',
	})
	const [message, setMessage] = useState('')
	const [isSuccess, setIsSuccess] = useState(false)
	const { id } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		const fetchEmployeeData = async () => {
			try {
				const data = await getEmployeeById(id)
				setEmployeeData(data)
			} catch (error) {
				console.error('Error fetching employee data:', error)
			}
		}
		fetchEmployeeData()
	}, [id])

	const handleChange = (event) => {
		setEmployeeData({
			...employeeData,
			[event.target.name]: event.target.value,
		})
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		try {
			await updateEmployee({ ...employeeData, id })
			setMessage('Employee updated successfully!')
			setIsSuccess(true)
			setTimeout(() => navigate('/employees'), 1000)
		} catch (error) {
			console.error('Error updating employee:', error)
			setMessage('Failed to update employee. Please try again.')
			setIsSuccess(false)
		}
	}

	return (
		<Container component='main' maxWidth='xs'>
			<Typography component='h1' variant='h5'>
				Update Employee
			</Typography>
			<Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
				<TextField
					margin='normal'
					required
					fullWidth
					name='name'
					label='Name'
					type='text'
					id='name'
					value={employeeData.name}
					onChange={handleChange}
				/>
				<TextField
					margin='normal'
					required
					fullWidth
					name='pin'
					label='PIN'
					type='password'
					id='pin'
					value={employeeData.pin}
					onChange={handleChange}
				/>
				<TextField
					margin='normal'
					fullWidth
					name='address'
					label='Address'
					type='text'
					id='address'
					value={employeeData.address}
					onChange={handleChange}
				/>
				<FormControl fullWidth margin='normal'>
					<InputLabel id='type-label'>Type</InputLabel>
					<Select
						labelId='type-label'
						id='type'
						name='type'
						value={employeeData.type}
						label='Type'
						onChange={handleChange}
					>
						<MenuItem value='hourly'>Hourly</MenuItem>
						<MenuItem value='salary'>Salary</MenuItem>
					</Select>
				</FormControl>
				<TextField
					margin='normal'
					fullWidth
					name='payRate'
					label='Pay Rate'
					type='number'
					id='payRate'
					value={employeeData.payRate}
					onChange={handleChange}
				/>
				<FormControl fullWidth margin='normal'>
					<InputLabel id='role-label'>Role</InputLabel>
					<Select
						labelId='role-label'
						id='role'
						name='role'
						value={employeeData.role}
						label='Role'
						onChange={handleChange}
					>
						<MenuItem value='admin'>Admin</MenuItem>
						<MenuItem value='user'>User</MenuItem>
					</Select>
				</FormControl>
				<Button
					type='submit'
					fullWidth
					variant='contained'
					sx={{ mt: 3, mb: 2 }}
				>
					Update Employee
				</Button>
				{message && (
					<Alert severity={isSuccess ? 'success' : 'error'} sx={{ mt: 2 }}>
						{message}
					</Alert>
				)}
			</Box>
		</Container>
	)
}

export default UpdateEmployee
