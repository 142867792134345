import React, { useState, useEffect } from 'react'
import { getAllEmployees, getAllTimeEntries } from '../../utility/api'
import {
	TextField,
	Card,
	CardContent,
	Typography,
	Container,
	Button,
	Grid,
	Stack
} from '@mui/material'
import Navbar from '../../views/Navbar'

function EmployeeTimes() {
	const [employees, setEmployees] = useState([])
	const [timeEntries, setTimeEntries] = useState([])
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [tempStartDate, setTempStartDate] = useState('')
	const [tempEndDate, setTempEndDate] = useState('')
	const [filteredEntries, setFilteredEntries] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			const employeesData = await getAllEmployees()
			const timeEntriesData = await getAllTimeEntries()
			setEmployees(employeesData)
			setTimeEntries(timeEntriesData)
		}
		fetchData()
	}, [])

	useEffect(() => {
		if (startDate && endDate) {
			const filtered = timeEntries.filter((entry) => {
				const entryDate = new Date(entry.date)
				return (
					entryDate >= new Date(startDate) && entryDate <= new Date(endDate)
				)
			})

			const employeeHours = employees
				.filter((emp) => emp.type === 'hourly')
				.map((emp) => {
					const totalHours = filtered
						.filter((entry) => entry.employeeId === emp.id)
						.reduce((acc, curr) => acc + parseFloat(curr.dailyHours), 0)
					return { ...emp, totalHours: totalHours.toFixed(2) }
				})

			setFilteredEntries(employeeHours)
		}
	}, [startDate, endDate, employees, timeEntries])

	const handleDateSubmit = () => {
		setStartDate(tempStartDate)
		setEndDate(tempEndDate)
	}

	const handleReset = () => {
		setTempStartDate('')
		setTempEndDate('')
		setStartDate('')
		setEndDate('')
		setFilteredEntries([])
	}

	return (
    <>
      <Navbar />
      <Container sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" justifyContent="center">
              <TextField
                fullWidth
                type="date"
                label="Start Date"
                value={tempStartDate}
                onChange={(e) => setTempStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                type="date"
                label="End Date"
                value={tempEndDate}
                onChange={(e) => setTempEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <Button onClick={handleDateSubmit} variant="contained" color="primary">
                Submit
              </Button>
              <Button onClick={handleReset} variant="contained" color="primary">
                Reset
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {filteredEntries.map((emp) => (
              <Card key={emp.id} variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                    {emp.name}
                  </Typography>
                  <Typography color="textSecondary">
                    Total Hours: {emp.totalHours}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default EmployeeTimes
