import React, { useState, useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Typography,
	Box,
} from '@mui/material'
import { getTotalHoursForJobDetailsId } from '../../utility/api'

function EmployeeHoursDetailsModal({ open, onClose, jobDetailId }) {
	const [totalHours, setTotalHours] = useState(null) 

	useEffect(() => {
		if (jobDetailId) {
			fetchTotalHours(jobDetailId);
		}
	}, [jobDetailId]); 

	const fetchTotalHours = async (jobDetailId) => {
    try {
        const hours = await getTotalHoursForJobDetailsId(jobDetailId);
        console.log("Fetched hours: ", hours); 
        setTotalHours(hours);
    } catch (error) {
        console.error('Error fetching total hours:', error);
        setTotalHours(null);
    }
};

	return (
		<Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
			<DialogTitle style={{ fontWeight: 'bold' }}>
				Employee Hours
			</DialogTitle>
			<DialogContent>
				{totalHours !== null ? (
					<Typography variant='body1'>
						Total Hours Worked: {totalHours}
					</Typography>
				) : (
					<Typography variant='body1'>Unable to fetch total hours.</Typography>
				)}
			</DialogContent>
			<Box padding={2} display='flex' justifyContent='flex-end'>
				<Button onClick={onClose} variant='contained' color='primary'>
					Close
				</Button>
			</Box>
		</Dialog>
	)
}

export default EmployeeHoursDetailsModal
