import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { login } from '../../utility/api'
import { setToken } from '../../utility/utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Login(props) {
	const [employeeData, setEmployeeData] = useState({
		name: '',
		pin: '',
	})
	const [loginError, setLoginError] = useState()
	const navigate = useNavigate()

	const handleChange = (event) => {
		setEmployeeData({
			...employeeData,
			[event.target.name]: event.target.value,
		})
	}
	const handleSubmit = async (event) => {
		event.preventDefault()
		try {
			const response = await login(employeeData)
			setToken(response.token)
			const userRole = response.role
			if (userRole === 'admin') {
				navigate('/employees')
			} else {
				navigate('/time-entries')
			}

			window.location.reload()
		} catch (error) {
			setLoginError(error)
		}
	}

	const checkLoginError = () => {
		if (loginError) {
			return (
				<Typography color={'red'}>Please enter a valid name and pin</Typography>
			)
		}
	}

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingBottom: 10,
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Login
				</Typography>
				<Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						margin='normal'
						required
						fullWidth
						id='name'
						label='Name'
						name='name'
						value={employeeData.name}
						onChange={handleChange}
						autoFocus
					/>
					<TextField
						margin='normal'
						required
						fullWidth
						name='pin'
						label='Pin'
						type='password'
						id='pin'
						onChange={handleChange}
						value={employeeData.pin}
					/>

					<Button
						type='submit'
						fullWidth
						variant='contained'
						sx={{ mt: 3, mb: 2 }}
					>
						Login
					</Button>
				</Box>
			</Box>
			<div>{checkLoginError()}</div>
		</Container>
	)
}

export default Login
