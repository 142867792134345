import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import CssBaseline from '@mui/material/CssBaseline'
import { addJobDetails, getAllEmployees } from '../../utility/api'
import Alert from '@mui/material/Alert'
import Navbar from '../../views/Navbar'

function AddJobDetail() {
	const location = useLocation()
	const { jobDescriptionId } = location.state || {}
	const [date, setDate] = useState('')
	const [notes, setNotes] = useState('')
	const [submittedBy, setSubmittedBy] = useState('')
	const [message, setMessage] = useState('')
	const [isSuccess, setIsSuccess] = useState(false)
	const [employees, setEmployees] = useState([])
	const [employeeHours, setEmployeeHours] = useState([
		{ employeeId: '', hours: '' },
	])

	useEffect(() => {
		const fetchEmployees = async () => {
			try {
				const allEmployees = await getAllEmployees()
				setEmployees(allEmployees)
			} catch (error) {
				console.error('Error fetching employees:', error)
			}
		}

		fetchEmployees()
	}, [])

	const handleEmployeeChange = (index, event) => {
		const newEmployeeHours = [...employeeHours]
		newEmployeeHours[index].employeeId = event.target.value
		setEmployeeHours(newEmployeeHours)
	}

	const handleHoursChange = (index, event) => {
		const newEmployeeHours = [...employeeHours]
		newEmployeeHours[index].hours = event.target.value
		setEmployeeHours(newEmployeeHours)
	}

	const addEmployeeHours = () => {
		setEmployeeHours([...employeeHours, { employeeId: '', hours: '' }])
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		const jobDetail = {
			job_description_id: jobDescriptionId,
			date,
			notes,
			submittedBy,
			employeeHours, // Ensure this aligns with how your API expects to receive the data
		}

		try {
			await addJobDetails(jobDetail)
			setMessage('Job detail added successfully!')
			setIsSuccess(true)
			// Resetting the form fields
			setDate('')
			setNotes('')
			setSubmittedBy('')
			setEmployeeHours([{ employeeId: '', hours: '' }])
		} catch (error) {
			setMessage('Failed to add job detail. Please try again.')
			setIsSuccess(false)
		}
	}

	return (
		<>
			<Navbar />
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography component='h1' variant='h5'>
						Add New Job Detail
					</Typography>
					<Box
						component='form'
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin='normal'
							required
							fullWidth
							id='date'
							label='Date'
							name='date'
							type='date'
							InputLabelProps={{ shrink: true }}
							value={date}
							onChange={(e) => setDate(e.target.value)}
						/>
						<TextField
							margin='normal'
							required
							fullWidth
							id='notes'
							label='Notes'
							name='notes'
							multiline
							rows={4}
							value={notes}
							onChange={(e) => setNotes(e.target.value)}
						/>
						<TextField
							margin='normal'
							fullWidth
							id='submittedBy'
							label='Submitted By'
							name='submittedBy'
							value={submittedBy}
							onChange={(e) => setSubmittedBy(e.target.value)}
						/>
						{/* Employee-Hour Entries as before */}
						{employeeHours.map((entry, index) => (
							<div key={index}>
								<FormControl fullWidth sx={{ mt: 2 }}>
									<InputLabel>Select Employee</InputLabel>
									<Select
										value={entry.employeeId}
										label='Select Employee'
										onChange={(e) => handleEmployeeChange(index, e)}
									>
										{employees.map((employee) => (
											<MenuItem key={employee.id} value={employee.id}>
												{employee.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									fullWidth
									label='Hours Worked'
									type='number'
									value={entry.hours}
									onChange={(e) => handleHoursChange(index, e)}
									sx={{ mt: 1 }}
								/>
							</div>
						))}
						<Button
							onClick={addEmployeeHours}
							variant='contained'
							sx={{ mt: 2, mb: 2 }}
						>
							Add Another Employee
						</Button>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}
						>
							Add Job Detail
						</Button>
					</Box>
					{message && (
						<Alert severity={isSuccess ? 'success' : 'error'} sx={{ mt: 2 }}>
							{message}
						</Alert>
					)}
				</Box>
			</Container>
		</>
	)
}

export default AddJobDetail
