import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Typography, Button } from '@mui/material'

function Navbar() {
	return (
		<AppBar position='static'>
			<Toolbar>
				<Typography
					variant='h6'
					component={Link}
					to='/'
					style={{ flexGrow: 1, textDecoration: 'none', color: 'inherit' }}
				>
					Immaculate Reflections
				</Typography>
				<Button color='inherit' component={Link} to='/employees'>
					Employees
				</Button>
				<Button color='inherit' component={Link} to='/job-descriptions'>
					Jobs
				</Button>
			</Toolbar>
		</AppBar>
	)
}

export default Navbar
