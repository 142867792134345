import { Paper } from '@mui/material'

function NotFound (props) {
  return (
    <Paper>
      NotFound
    </Paper>
  )
}

export default NotFound