import React, { useState, useEffect, useCallback } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { TimePicker } from '@mui/x-date-pickers'
import { Box, Button, Typography, Alert } from '@mui/material'
import {
	createNewTimeEntry,
	getMe,
	getTimeEntriesByEmployeeId,
} from '../../utility/api'

function TimeEntries(props) {
	const [date, setDate] = useState(null)
	const [startTime, setStartTime] = useState(null)
	const [endTime, setEndTime] = useState(null)
	const [dailyHours, setDailyHours] = useState(0)
	const [message, setMessage] = useState('')
	const [isSuccess, setIsSuccess] = useState(false)
	const [showAlert, setShowAlert] = useState(false)

	const fetchAndCalculateHours = useCallback(async () => {
		try {
			const { id: employeeId } = await getMe()
			const entries = await getTimeEntriesByEmployeeId(employeeId)
			calculateDailyHoursForDate(date, entries)
		} catch (error) {
			console.error('Error fetching time entries:', error)
			setMessage('Failed to load times. Please try again.')
			setIsSuccess(false)
			setShowAlert(true)
		}
	}, [date]) // include date as a dependency

	useEffect(() => {
		if (date) {
			fetchAndCalculateHours()
		}
	}, [date, fetchAndCalculateHours])

	const calculateDailyHoursForDate = (selectedDate, entries) => {
		if (!selectedDate || !entries) return

		let totalHours = 0
		const selectedDateString = new Date(selectedDate)
			.toISOString()
			.split('T')[0]

		entries.forEach((entry) => {
			const entryDate = new Date(entry.date).toISOString().split('T')[0]
			if (entryDate === selectedDateString) {
				totalHours += parseFloat(entry.dailyHours)
			}
		})

		setDailyHours(totalHours)
	}

	const handleSubmit = async () => {
		if (!date || !startTime || !endTime) {
			setMessage('Date, start time, and end time are required.')
			setIsSuccess(false)
			setShowAlert(true)
			return
		}

		const formattedDate = date.toISOString().slice(0, 10)
		const formattedStartTime = new Date(
			formattedDate + 'T' + startTime.toISOString().split('T')[1]
		).toISOString()
		const formattedEndTime = new Date(
			formattedDate + 'T' + endTime.toISOString().split('T')[1]
		).toISOString()

		const hoursDiff =
			(new Date(formattedEndTime).getTime() -
				new Date(formattedStartTime).getTime()) /
			(1000 * 60 * 60)

		try {
			const { id: employeeId } = await getMe()
			const timeEntryData = {
				date: formattedDate,
				startTime: formattedStartTime,
				endTime: formattedEndTime,
				dailyHours: hoursDiff,
				employeeId: employeeId,
			}

			await createNewTimeEntry(timeEntryData)
			await fetchAndCalculateHours()

			setMessage('Hours submitted successfully!')
			setIsSuccess(true)
			setShowAlert(true)
			setTimeout(() => setShowAlert(false), 3000)
		} catch (error) {
			console.error('Error submitting time entry:', error)
			setMessage('Failed to submit times. Please try again.')
			setIsSuccess(false)
			setShowAlert(true)
			setTimeout(() => setShowAlert(false), 3000)
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => setShowAlert(false), 3000)
		return () => clearTimeout(timer)
	}, [showAlert])

	return (
		<div>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<StaticDatePicker
					displayStaticWrapperAs='desktop'
					openTo='day'
					value={date}
					onChange={(newDate) => setDate(newDate)}
				/>
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					height='20vh'
					marginTop='20px'
				>
					<Box mb={1}>
						<TimePicker
							label='Start Time'
							value={startTime}
							onChange={(newTime) => setStartTime(newTime)}
							slotProps={{ textField: { variant: 'outlined' } }}
						/>
					</Box>
					<Box mt={1}>
						<TimePicker
							label='End Time'
							value={endTime}
							onChange={(newTime) => setEndTime(newTime)}
							slotProps={{ textField: { variant: 'outlined' } }}
						/>
					</Box>
				</Box>
			</LocalizationProvider>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '10vh',
				}}
			>
				<Button variant='contained' color='primary' onClick={handleSubmit}>
					Submit
				</Button>
			</div>
			<Typography
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '5vh',
				}}
				variant='h6'
			>
				Daily Hours: {dailyHours}
			</Typography>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '10vh',
				}}
			></div>
			{showAlert && (
				<Alert severity={isSuccess ? 'success' : 'error'} sx={{ mt: 2 }}>
					{message}
				</Alert>
			)}
		</div>
	)
}

export default TimeEntries
