import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getJobDetailsByJobDescriptionId } from '../../utility/api'
import Navbar from '../Navbar'
import EmployeeHoursDetailsModal from './EmployeeHoursDetails'
import { Card, CardContent, Typography, Button } from '@mui/material'

function JobDetails() {
	const { jobDescriptionId } = useParams()
	const [jobDetails, setJobDetails] = useState([])
	const navigate = useNavigate()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [currentJobDetailId, setCurrentJobDetailId] = useState(null)

	useEffect(() => {
		const fetchJobDetails = async () => {
			try {
				const details = await getJobDetailsByJobDescriptionId(jobDescriptionId)
				setJobDetails(details)
			} catch (error) {
				console.error('Failed to fetch job details:', error)
			}
		}

		if (jobDescriptionId) {
			fetchJobDetails()
		}
	}, [jobDescriptionId])

	const handleAddJobDetailClick = () => {
		navigate('/job-details/new', { state: { jobDescriptionId } })
	}

	const handleViewDetailsClick = (jobDetailId) => {
		setCurrentJobDetailId(jobDetailId)
		setIsModalOpen(true)
	}

	return (
		<>
			<Navbar />
			<div style={{ margin: 20 }}>
				<Button
					variant='contained'
					color='primary'
					onClick={handleAddJobDetailClick}
					style={{ marginBottom: 20 }}
				>
					Add Job Details
				</Button>
				{jobDetails.length > 0 ? (
					jobDetails.map((detail) => (
						<Card key={detail.id} sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant='h6' component='div'>
									Date: {new Date(detail.date).toLocaleDateString()}
								</Typography>
								<Typography variant='body1'>Notes: {detail.notes}</Typography>
								<Typography variant='body1'>
									Submitted By: {detail.submittedBy || 'N/A'}
								</Typography>
								{/* Add a button to view employee hours details for each job detail */}
								<Button
									size='small'
									onClick={() => handleViewDetailsClick(detail.id)}
								>
									View Hours Details
								</Button>
							</CardContent>
						</Card>
					))
				) : (
					<Typography variant='body1'>No job details available.</Typography>
				)}

				{/* Employee Hours Details Modal */}
				<EmployeeHoursDetailsModal
					open={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					jobDetailId={currentJobDetailId}
				/>
			</div>
		</>
	)
}

export default JobDetails
