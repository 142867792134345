import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createNewEmployee } from '../../utility/api'
import {
	Button,
	TextField,
	Container,
	Typography,
	Box,
	CssBaseline,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Alert,
} from '@mui/material'
import Navbar from '../../views/Navbar'

function AddEmployees() {
	const [name, setName] = useState('')
	const [pin, setPin] = useState('')
	const [address, setAddress] = useState('')
	const [type, setType] = useState('')
	const [payRate, setPayRate] = useState('')
	const [role, setRole] = useState('user')
	const [message, setMessage] = useState('')
	const [isSuccess, setIsSuccess] = useState(false)
	const navigate = useNavigate()

	const handleSubmit = async (event) => {
		event.preventDefault()
		const employee = {
			name,
			pin,
			address,
			type,
			payRate: parseInt(payRate, 10),
			role,
		}

		try {
			await createNewEmployee(employee)
			setMessage('Employee added successfully!')
			setIsSuccess(true)
			setTimeout(() => navigate('/employees'), 1000) // Adjust the route as needed
		} catch (error) {
			setMessage('Failed to add employee. Please try again.')
			setIsSuccess(false)
		}

		// Reset form fields
		setName('')
		setPin('')
		setAddress('')
		setType('')
		setPayRate('')
		setRole('user')
	}

	return (
		<>
			<Navbar />
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography component='h1' variant='h5'>
						Add Employee
					</Typography>
					<Box
						component='form'
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='name'
							label='Name'
							name='name'
							autoFocus
							onChange={(e) => setName(e.target.value)}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							name='pin'
							label='PIN'
							type='password'
							id='pin'
							onChange={(e) => setPin(e.target.value)}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							fullWidth
							name='address'
							label='Address'
							type='text'
							id='address'
							onChange={(e) => setAddress(e.target.value)}
						/>
						<FormControl fullWidth margin='normal'>
							<InputLabel id='type-label'>Type</InputLabel>
							<Select
								labelId='type-label'
								id='type'
								value={type}
								label='Type'
								onChange={(e) => setType(e.target.value)}
							>
								<MenuItem value='hourly'>Hourly</MenuItem>
								<MenuItem value='salary'>Salary</MenuItem>
							</Select>
						</FormControl>
						<TextField
							variant='outlined'
							margin='normal'
							fullWidth
							name='payRate'
							label='Pay Rate'
							type='number'
							id='payRate'
							onChange={(e) => setPayRate(e.target.value)}
						/>
						<FormControl fullWidth margin='normal'>
							<InputLabel id='role-label'>Role</InputLabel>
							<Select
								labelId='role-label'
								id='role'
								value={role}
								label='Role'
								onChange={(e) => setRole(e.target.value)}
							>
								<MenuItem value='admin'>Admin</MenuItem>
								<MenuItem value='user'>User</MenuItem>
							</Select>
						</FormControl>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}
						>
							Add Employee
						</Button>
					</Box>
					{message && (
						<Alert severity={isSuccess ? 'success' : 'error'} sx={{ mt: 2 }}>
							{message}
						</Alert>
					)}
				</Box>
			</Container>
		</>
	)
}

export default AddEmployees
