import { Routes, Route } from 'react-router-dom'
import Login from './views/Login'
import NotFound from './views/NotFound'
import TimeEntries from './views/TimeEntries'
import JobDescriptions from './views/JobDescriptions'
import JobDetails from './views/JobDetails'
import AddJobDetail from './views/JobDetails/AddNewJobDetails'
import AddNewJob from './views/JobDescriptions/AddNewJob'
import ViewEmployees from './views/Employees/index'
import AddEmployees from './views/Employees/AddEmployees'
import EmployeeTimes from './views/Employees/EmployeeTimes'
import UpdateEmployee from './views/Employees/UpdateEmployee'
import Layout from './layouts'
import ThemeContextProvider from './context/themeContext'

function App() {
	return (
		<div className='App'>
			<ThemeContextProvider>
				<Routes>
					<Route path='/' element={<Layout />}>
						<Route index element={<Login />} />
						<Route path='*' element={<NotFound />} />
						<Route path='/time-entries' element={<TimeEntries />} />
						<Route path='/job-descriptions' element={<JobDescriptions />} />
						<Route path='/job-details/job-description-details/:jobDescriptionId' element={<JobDetails />} />
						<Route path='/job-details/new' element={<AddJobDetail />} />
						<Route path='/job-descriptions/new' element={<AddNewJob />} />
						<Route path='/employees' element={<ViewEmployees />} />
						<Route path='/employees/new' element={<AddEmployees />} />
						<Route path='/employees/update/:id' element={<UpdateEmployee />} />
						<Route path='/employees/time-entries' element={<EmployeeTimes />} />
					</Route>
				</Routes>
			</ThemeContextProvider>
		</div>
	)
}

export default App
