import React, { useState, useEffect } from 'react'
import { getAllEmployees, deleteEmployee } from '../../utility/api'
import { Link, useNavigate } from 'react-router-dom'
import {
	Card,
	CardContent,
	Typography,
	Grid,
	Container,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import Navbar from '../../views/Navbar'

function ViewEmployees() {
	const [employees, setEmployees] = useState([])
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
	const [employeeToDelete, setEmployeeToDelete] = useState(null)
	const navigate = useNavigate()

	useEffect(() => {
		fetchEmployees()
	}, [])

	const fetchEmployees = async () => {
		try {
			const response = await getAllEmployees()
			setEmployees(response)
		} catch (error) {
			console.error('Error fetching employees:', error)
		}
	}

	const handleDeleteClick = (employee) => {
		setEmployeeToDelete(employee)
		setDeleteDialogOpen(true)
	}

	const handleDelete = async () => {
		try {
			await deleteEmployee(employeeToDelete.id)
			setDeleteDialogOpen(false)
			fetchEmployees() // Refresh the list after deletion
		} catch (error) {
			console.error('Error deleting employee:', error)
		}
	}

	const handleUpdateClick = (employeeId) => {
		navigate(`/employees/update/${employeeId}`)
	}

	const handleCloseDialog = () => {
		setDeleteDialogOpen(false)
	}

	return (
		<>
			<Navbar />
			<Container maxWidth='lg'>
				<Link to='/employees/time-entries'>
					<Button sx={{ margin: '10px' }} variant='contained'>
						View Employees Times
					</Button>
				</Link>
				<Link to='/employees/new'>
					<Button sx={{ margin: '10px' }} variant='contained'>
						Add New Employee
					</Button>
				</Link>
				<Grid container spacing={3}>
					{employees.map((employee) => (
						<Grid item xs={12} sm={6} md={4} key={employee.id}>
							<Card>
								<CardContent>
									<Typography variant='h6'>{employee.name}</Typography>
									<Typography color='textSecondary'>
										PIN: {employee.pin}
									</Typography>
									<Typography color='textSecondary'>
										Address: {employee.address || 'N/A'}
									</Typography>
									<Typography color='textSecondary'>
										Type: {employee.type}
									</Typography>
									<Typography color='textSecondary'>
										Pay Rate: {employee.payRate || 'N/A'}
									</Typography>
									<Typography color='textSecondary'>
										Role: {employee.role}
									</Typography>
									<Button
										variant='contained'
										color='primary'
										onClick={() => handleUpdateClick(employee.id)}
										sx={{ mt: 1, mr: 1 }}
									>
										Update
									</Button>
									<Button
										variant='contained'
										color='primary'
										onClick={() => handleDeleteClick(employee)}
										sx={{ mt: 1 }}
									>
										Delete
									</Button>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>

				<Dialog
					open={deleteDialogOpen}
					onClose={handleCloseDialog}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{'Confirm Deletion'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							Are you sure you want to delete this employee?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog} color='primary'>
							Cancel
						</Button>
						<Button onClick={handleDelete} color='primary' autoFocus>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</>
	)
}

export default ViewEmployees
