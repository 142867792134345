import React, { useState} from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import CssBaseline from '@mui/material/CssBaseline'
import { addJobDescription} from '../../utility/api'
import Alert from '@mui/material/Alert'
import Navbar from '../../views/Navbar'

function AddNewJob() {
	const [location, setLocation] = useState('')
	const [contractedBy, setContractedBy] = useState('')
	const [message, setMessage] = useState('')
	const [isSuccess, setIsSuccess] = useState(false)

	const handleSubmit = async (event) => {
		event.preventDefault()
		const job = {
			location,
			contractedBy,
		}

		try {
			await addJobDescription(job)
			setMessage('Job description added successfully!')
			setIsSuccess(true)
		} catch (error) {
			setMessage('Failed to add job description. Please try again.')
			setIsSuccess(false)
		}

		// Resetting the form fields

		setLocation('')
		setContractedBy('')
	}

	return (
		<>
			<Navbar />
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography component='h1' variant='h5'>
						Add New Job
					</Typography>
					<Box
						component='form'
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						{/* Fields for date, location, etc. */}
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='location'
							label='Location'
							name='location'
							value={location}
							onChange={(e) => setLocation(e.target.value)}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='contractedBy'
							label='Contracted By'
							name='contractedBy'
							value={contractedBy}
							onChange={(e) => setContractedBy(e.target.value)}
						/>
						{/* Submit button */}
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}
						>
							Add Job Description
						</Button>
					</Box>
					{message && (
						<Alert severity={isSuccess ? 'success' : 'error'} sx={{ mt: 2 }}>
							{message}
						</Alert>
					)}
				</Box>
			</Container>
		</>
	)
}

export default AddNewJob
